import React,{useState, useRef, useEffect} from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import { useShippingDataMutation, useCartQuantityDataMutation } from "../features/slice/ShippingApiSlice";

import { useDispatch } from "react-redux";

function Checkout() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    contact_person_name: "",
    contact_whatsapp_no : "",
    delivery_no : "",
    house_no : "",
    street : "",
    landmark : "",
    city : "",
    state : "",  
    pincode : "",
    email : "",
    customer_id : 1
  })
const customer_id = '1';
const [cartDetails, setCartDetails] = useState([]);
  const [shippingData] = useShippingDataMutation();
  const [cartQuantityData] = useCartQuantityDataMutation();
  const formRef = useRef(null);
  
  useEffect(()=> {
    const fetchCartData = async () => {
      try {
        console.log('customer_id',customer_id)
        const result = await cartQuantityData({customer_id});
        setCartDetails(result.data);
        console.log(result.data);
      } catch (err) {
        console.error('Failed to fetch cart data:', err);
      }
    }
    fetchCartData();
  },[cartQuantityData]);

  console.log('cartDetails', cartDetails);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('FormSubmitted',formData)
    try {
      const result = shippingData(formData);
      console.log('response',result);
    } catch (error) {
      console.error('Error',error);
    }
  }

  const triggerSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
    }
  };

  //use reduce to sum up the no_of_product values
  const totalNoOfProduct = cartDetails.reduce((total, item)=> {
    return total + item.no_of_product;
  },0);

  console.log('total no of product:', totalNoOfProduct);

  //use reduce to sum up the amount values
  const totalAmount = cartDetails.reduce((total, item) => {
    return total + item.cart_amount;
  },0)

  console.log('totalAmount',totalAmount);

  return (
    <>
      <div class="container">
        <div class="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="shipping-icon"
            viewBox="0 0 2000 2000"
            id="delivery-service"
          >
            <g>
              <path
                fill="#e2eefc"
                d="M204.05,1651.47s-301.33-352.15,127.14-465.29,541.37-210,743.93-503.17,1074.1-214.76,719.59,982Z"
              ></path>
              <path
                fill="#e2eefc"
                d="M322.91,962.41C603.79,969.19,883,814.76,1026.5,573.23c27.71-46.64,16.68-112.59-24.7-147.67s-108.25-35.17-149.72-.19c-68.65,57.89-159.68,80.71-248,96.79S424.59,549.92,344.72,591,196.91,710.66,198.16,800.45,292.21,961.67,322.91,962.41Z"
              ></path>
              <rect
                width="328"
                height="393.49"
                x="1438.05"
                y="1245.6"
                fill="#ccdeff"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1458.63"
                y="1266.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1556.63"
                y="1266.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1652.63"
                y="1266.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1459.63"
                y="1293.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1557.63"
                y="1293.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1653.63"
                y="1293.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1459.63"
                y="1320.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1557.63"
                y="1320.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1653.63"
                y="1320.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1460.63"
                y="1347.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1558.63"
                y="1347.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1654.63"
                y="1347.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1461.63"
                y="1374.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1559.63"
                y="1374.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1655.63"
                y="1374.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1461.63"
                y="1401.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1559.63"
                y="1401.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="81"
                height="15"
                x="1655.63"
                y="1401.19"
                fill="#e2eefc"
              ></rect>
              <rect
                width="275"
                height="100"
                x="1461.63"
                y="1442.34"
                fill="#e2eefc"
              ></rect>
              <rect
                width="102"
                height="482.15"
                x="1671.41"
                y="1156.94"
                fill="#ccdeff"
              ></rect>
              <rect
                width="30"
                height="438"
                x="1763.76"
                y="1201.09"
                fill="#ccdeff"
              ></rect>
              <rect
                width="158"
                height="254"
                x="288.97"
                y="1386.1"
                fill="#ccdeff"
              ></rect>
              <rect
                width="117.5"
                height="23"
                x="305.53"
                y="1411.18"
                fill="#e2eefc"
              ></rect>
              <rect
                width="117.5"
                height="23"
                x="305.53"
                y="1449.6"
                fill="#e2eefc"
              ></rect>
              <rect
                width="117.5"
                height="23"
                x="305.59"
                y="1488.01"
                fill="#e2eefc"
              ></rect>
              <rect
                width="117.5"
                height="23"
                x="305.59"
                y="1525.64"
                fill="#e2eefc"
              ></rect>
              <rect
                width="158"
                height="335"
                x="404.07"
                y="1305.1"
                fill="#ccdeff"
              ></rect>
              <rect
                width="353.11"
                height="269"
                x="547.07"
                y="1371.1"
                fill="#ccdeff"
              ></rect>
              <rect
                width="88"
                height="149"
                x="200.97"
                y="1491.1"
                fill="#ccdeff"
              ></rect>
              <rect
                width="3"
                height="126.14"
                x="605.7"
                y="1244.96"
                fill="#ccdeff"
              ></rect>
              <rect
                width="3"
                height="97.1"
                x="639.38"
                y="1274"
                fill="#ccdeff"
              ></rect>
              <rect
                width="7"
                height="146.27"
                x="575.11"
                y="1224.83"
                fill="#ccdeff"
              ></rect>
              <rect
                width="29"
                height="36.41"
                x="307.52"
                y="1349.69"
                fill="#ccdeff"
              ></rect>
              <rect
                width="63"
                height="39.34"
                x="416.07"
                y="1265.75"
                fill="#ccdeff"
              ></rect>
              <rect
                width="27"
                height="80.27"
                x="507.18"
                y="1224.83"
                fill="#ccdeff"
              ></rect>
              <rect
                width="24"
                height="88.14"
                x="352.34"
                y="1297.96"
                fill="#ccdeff"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="211.97"
                y="1505.6"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="250.47"
                y="1505.6"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="211.97"
                y="1545.1"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="250.47"
                y="1545.1"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="211.97"
                y="1584.6"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="250.47"
                y="1584.6"
                fill="#e2eefc"
              ></rect>
              <rect
                width="9"
                height="303"
                x="423.09"
                y="1326.39"
                fill="#e2eefc"
              ></rect>
              <rect
                width="9"
                height="303"
                x="463.09"
                y="1326.39"
                fill="#e2eefc"
              ></rect>
              <rect
                width="9"
                height="303"
                x="499.1"
                y="1326.39"
                fill="#e2eefc"
              ></rect>
              <rect
                width="9"
                height="303"
                x="537.11"
                y="1326.39"
                fill="#e2eefc"
              ></rect>
              <rect
                width="158"
                height="254"
                x="693.63"
                y="1373.81"
                fill="#ccdeff"
              ></rect>
              <rect
                width="117.5"
                height="23"
                x="710.2"
                y="1398.9"
                fill="#e2eefc"
              ></rect>
              <rect
                width="117.5"
                height="23"
                x="710.2"
                y="1437.31"
                fill="#e2eefc"
              ></rect>
              <rect
                width="117.5"
                height="23"
                x="710.26"
                y="1475.73"
                fill="#e2eefc"
              ></rect>
              <rect
                width="117.5"
                height="23"
                x="710.26"
                y="1513.36"
                fill="#e2eefc"
              ></rect>
              <rect
                width="158"
                height="335"
                x="808.74"
                y="1292.81"
                fill="#ccdeff"
              ></rect>
              <rect
                width="353.11"
                height="269"
                x="951.74"
                y="1358.81"
                fill="#ccdeff"
              ></rect>
              <rect
                width="88"
                height="149"
                x="605.63"
                y="1478.81"
                fill="#ccdeff"
              ></rect>
              <rect
                width="3"
                height="126.14"
                x="1010.37"
                y="1232.68"
                fill="#ccdeff"
              ></rect>
              <rect
                width="3"
                height="97.1"
                x="1044.05"
                y="1261.71"
                fill="#ccdeff"
              ></rect>
              <rect
                width="7"
                height="146.27"
                x="979.78"
                y="1212.54"
                fill="#ccdeff"
              ></rect>
              <rect
                width="29"
                height="36.41"
                x="712.19"
                y="1337.41"
                fill="#ccdeff"
              ></rect>
              <rect
                width="63"
                height="39.34"
                x="820.74"
                y="1253.47"
                fill="#ccdeff"
              ></rect>
              <rect
                width="27"
                height="80.27"
                x="911.84"
                y="1212.54"
                fill="#ccdeff"
              ></rect>
              <rect
                width="24"
                height="88.14"
                x="757.01"
                y="1285.68"
                fill="#ccdeff"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="616.64"
                y="1493.31"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="655.14"
                y="1493.31"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="616.64"
                y="1532.81"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="655.14"
                y="1532.81"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="616.64"
                y="1572.31"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="655.14"
                y="1572.31"
                fill="#e2eefc"
              ></rect>
              <rect
                width="9"
                height="303"
                x="827.76"
                y="1314.1"
                fill="#e2eefc"
              ></rect>
              <rect
                width="9"
                height="303"
                x="867.76"
                y="1314.1"
                fill="#e2eefc"
              ></rect>
              <rect
                width="9"
                height="303"
                x="903.77"
                y="1314.1"
                fill="#e2eefc"
              ></rect>
              <rect
                width="9"
                height="303"
                x="941.78"
                y="1314.1"
                fill="#e2eefc"
              ></rect>
              <rect
                width="158"
                height="254"
                x="1098.1"
                y="1351.65"
                fill="#ccdeff"
              ></rect>
              <rect
                width="117.5"
                height="23"
                x="1114.67"
                y="1376.74"
                fill="#e2eefc"
              ></rect>
              <rect
                width="117.5"
                height="23"
                x="1114.67"
                y="1415.15"
                fill="#e2eefc"
              ></rect>
              <rect
                width="117.5"
                height="23"
                x="1114.73"
                y="1453.56"
                fill="#e2eefc"
              ></rect>
              <rect
                width="117.5"
                height="23"
                x="1114.73"
                y="1491.2"
                fill="#e2eefc"
              ></rect>
              <rect
                width="158"
                height="335"
                x="1213.2"
                y="1270.65"
                fill="#ccdeff"
              ></rect>
              <rect
                width="353.11"
                height="269"
                x="1356.2"
                y="1336.65"
                fill="#ccdeff"
              ></rect>
              <rect
                width="88"
                height="149"
                x="1010.1"
                y="1456.65"
                fill="#ccdeff"
              ></rect>
              <rect
                width="3"
                height="126.14"
                x="1414.84"
                y="1210.51"
                fill="#ccdeff"
              ></rect>
              <rect
                width="3"
                height="97.1"
                x="1448.52"
                y="1239.55"
                fill="#ccdeff"
              ></rect>
              <rect
                width="7"
                height="146.27"
                x="1384.25"
                y="1190.38"
                fill="#ccdeff"
              ></rect>
              <rect
                width="29"
                height="36.41"
                x="1116.66"
                y="1315.24"
                fill="#ccdeff"
              ></rect>
              <rect
                width="63"
                height="39.34"
                x="1225.21"
                y="1231.31"
                fill="#ccdeff"
              ></rect>
              <rect
                width="27"
                height="80.27"
                x="1316.31"
                y="1190.38"
                fill="#ccdeff"
              ></rect>
              <rect
                width="24"
                height="88.14"
                x="1161.48"
                y="1263.52"
                fill="#ccdeff"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="1021.1"
                y="1471.15"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="1059.6"
                y="1471.15"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="1021.1"
                y="1510.65"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="1059.6"
                y="1510.65"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="1021.1"
                y="1550.15"
                fill="#e2eefc"
              ></rect>
              <rect
                width="23.5"
                height="23.5"
                x="1059.6"
                y="1550.15"
                fill="#e2eefc"
              ></rect>
              <rect
                width="9"
                height="303"
                x="1232.23"
                y="1291.94"
                fill="#e2eefc"
              ></rect>
              <rect
                width="9"
                height="303"
                x="1272.23"
                y="1291.94"
                fill="#e2eefc"
              ></rect>
              <rect
                width="9"
                height="303"
                x="1308.24"
                y="1291.94"
                fill="#e2eefc"
              ></rect>
              <rect
                width="9"
                height="303"
                x="1346.24"
                y="1291.94"
                fill="#e2eefc"
              ></rect>
              <rect
                width="162"
                height="123.31"
                x="1276.05"
                y="1516.64"
                fill="#ccdeff"
              ></rect>
              <rect
                width="6"
                height="6"
                x="1584.1"
                y="1608.83"
                fill="#ccdeff"
              ></rect>
              <path
                fill="#e2eefc"
                d="M1354.2 457.06l-24.84-8c10-29.64 53.92-40.47 76.5-18.84-11.85-14.18-2-39.93 16.29-42.59s35 19.25 27.71 36.21c14.15-16.31 44.75-12.51 54.47 6.77 11.67-14.46 37.21-14 48.37.84A281.92 281.92 0 011709 446.73l-443.26 21.51C1297.63 462.41 1329.56 456.58 1354.2 457.06zM484.21 267l24.29-9.52c-11.8-29-56.36-37-77.53-14 10.93-14.9-.52-40-18.94-41.47s-33.76 21.42-25.38 37.88c-15.15-15.39-45.45-9.66-53.94 10.19-12.56-13.7-38-11.65-48.22 3.88a281.8 281.8 0 00-155 25.08l443.74-6.45C541 268.74 508.77 264.92 484.21 267z"
              ></path>
              <path
                fill="#1a1a35"
                d="M110.88 1665h0a30 30 0 0130-30h1728a30 30 0 0130 30h0a30 30 0 01-30 30h-1728A30 30 0 01110.88 1665zM630.23 1248.86v-96.29a11 11 0 0111-11h11v107.29z"
              ></path>
              <path
                fill="#fbb089"
                d="M548.68,1157.67c3.31-.19,3.12,1,5.55,3.25a10.32,10.32,0,0,1,2.19,3.32,35.59,35.59,0,0,1,3.15,18.72,68.33,68.33,0,0,1,21.18-12.9c-3.48-4.82-5.35-10.6-6.91-16.34a29.68,29.68,0,0,0-2-5.74,5.93,5.93,0,0,0-1.71-2.19,6.18,6.18,0,0,0-4.56-.68c-4.21.65-8,2.78-11.64,5q-4.14,2.58-8.09,5.44c-.92.67,2.89,1.48,2.81,2.62"
              ></path>
              <path
                fill="#e44531"
                d="M560,1234.22s-44.39,25.82-55.43,18.1c0,0-33.29-6.46-51.88-90.67-.55-2.46-1.08-5-1.6-7.6l12.8-5.28s2.24,2.69,5.8,7.26c9.7,12.45,29.2,38.86,40,63,0,0,33.88-31.92,55.07-39.42Z"
              ></path>
              <path
                fill="#f75c3d"
                d="M558.3,1185.46a2.5,2.5,0,0,0-1.59,4c-2.56.6-3.59,3.63-4.14,6.2-8.13,38-7.11,77.23-9.08,116a94.37,94.37,0,0,1-2,17,122.78,122.78,0,0,0,25.34-1.12c6.52-1,13-2.48,19.63-2.1,6,.34,11.77,2.23,17.67,3.31a66.53,66.53,0,0,0,27.35-.74c2.11-7,1.78-14.57,1-21.89-2.15-20.87-7.61-41.23-13-61.5-2.31-8.62-4.65-17.3-8.44-25.39a153,153,0,0,0-7.94-14.2l-18.56-30.57a4.45,4.45,0,0,0-2.13-2.15c-1.41-.46-2.82.57-4,1.5a83.39,83.39,0,0,1-22.34,12.88"
              ></path>
              <path
                fill="#38385c"
                d="M582.87,1163.93a257.48,257.48,0,0,0-30.09,18.66,11.46,11.46,0,0,1,.82,11.3,202.63,202.63,0,0,1,36.19-24.33,88.82,88.82,0,0,0-7.35-5.37"
              ></path>
              <rect
                width="193.96"
                height="103.92"
                x="507.88"
                y="1251.03"
                fill="#febe3d"
              ></rect>
              <rect
                width="48.96"
                height="103.92"
                x="507.88"
                y="1251.03"
                fill="#f8a23a"
              ></rect>
              <rect
                width="47.08"
                height="10.2"
                x="647.73"
                y="1257.01"
                fill="#fff"
              ></rect>
              <rect
                width="47.08"
                height="10.2"
                x="647.73"
                y="1270.96"
                fill="#fff"
              ></rect>
              <path
                fill="#e44531"
                d="M574.31,1173.19l9.7-3.42s3.27-3.64,27.3,19.71,59.38,77.59,59.38,77.59L623,1348.42l-15.61-10,21.26-72.3s-111.73-117.22-30.76-88.95"
              ></path>
              <path
                fill="#fbb089"
                d="M607.56,1337.86c-3.68,1-6.58,3-9.83,5a3.1,3.1,0,0,0-.88.68,3,3,0,0,0-.48,1.22,53.73,53.73,0,0,1-4.54,12.35,55.24,55.24,0,0,0,12.65,1.93,4.16,4.16,0,0,0,1.65-.17,3.78,3.78,0,0,0,1.61-1.41,20.86,20.86,0,0,0,2.51-4.34c4.84-1,9.32-1.21,12.72-4.67-4.31-2-8.14-6.13-12.2-8.62a26.77,26.77,0,0,0-3.27-2"
              ></path>
              <path
                fill="#e1c2b0"
                d="M585.39,1108.45c.47-.1.93-.22,1.39-.35l.28-.08h0l-.28.08C586.32,1108.23,585.85,1108.34,585.39,1108.45Z"
              ></path>
              <path
                fill="#e88a78"
                d="M532.28 1133a19 19 0 00.06 3.15c.47 5.1 2.06 7.59 4.1 8.55a3.52 3.52 0 00.45.18 6.58 6.58 0 003.69.1c-.47-3.23-.89-6.33-1.19-9-.22-1.92-.4-3.64-.51-5.07-.69.48-3.14-2.31-3.14-2.31C533.65 1129 532.6 1130.89 532.28 1133zM565.73 1166.82a.12.12 0 000 0S565.7 1166.82 565.73 1166.82zm-9.83-4.07c1.62.51 4.12-3.51 5.41-3.2 1.66.4 2.92.64 3.5.75l.13 0v.08l.09-.06.11 0v-.08c.3-.18.94-.58 1.8-1.15.58-.38 1.26-.85 2-1.37h0c1.33-1 5.09-.77 6.64-2.09.29 2.48-.45 3.48 1.13 6.86a11 11 0 01-.86.94 15.5 15.5 0 01-3.15 2.44 16.3 16.3 0 01-5.29 2 16.77 16.77 0 01-4.88.19 15.42 15.42 0 01-4.6-1.24c-.35-.16-.31-.41-.65-.6C557.74 1163.93 558.21 1169.74 555.9 1162.75zM583.35 1146.25a8.17 8.17 0 004.65 1 5.15 5.15 0 001.21-.32c2-.83 3.76-3.13 4.77-7.89 1.08-5.09 0-7.15-1.7-7.74l0 0a2.33 2.33 0 01-.75.5c-.94.93-4.61 1.1-5.2 2.14-.1.52-.22 1.07-.35 1.65-.05.26-.11.52-.17.79l-.09.4c-.06.27-.13.54-.19.83l0 .12c-.12.5-.24 1-.36 1.54l-.13.53s0 0 0 .06c-.07.27-.14.55-.2.82s-.1.42-.16.63C584.23 1142.91 583.81 1144.56 583.35 1146.25z"
              ></path>
              <path
                fill="#ffece6"
                d="M546.75,1123.37l12-2.4a7.52,7.52,0,0,0-1.09-.48,10.1,10.1,0,0,0-9.74,1.73h0A7.81,7.81,0,0,0,546.75,1123.37Z"
              ></path>
              <path
                fill="#fbb089"
                d="M538.88,1130.88h0c.12,1.43.29,3.15.51,5.07.31,2.7.72,5.79,1.19,9,.21,1.45.44,2.93.68,4.41.5,3.11,2.92,6.06,5.9,8.6a47.6,47.6,0,0,0,6,4.31c1.45.89,2.83,1.65,4,2.26,1.51.79,2.68,1.33,3.21,1.57l.12.06.1,0,.11,0c.33-.11,1-.34,2-.68.65-.23,1.43-.52,2.27-.85h0c1.52-.6,3.3-1.36,5.12-2.27a39.57,39.57,0,0,0,6.49-4c2.5-1.92,4.49-4.16,5.2-6.65.53-1.86,1-3.71,1.51-5.51s.87-3.35,1.26-4.91c0-.21.11-.42.16-.63s.14-.56.2-.82c0,0,0,0,0-.06,0-.18.09-.36.13-.53.13-.53.25-1,.36-1.54l0-.12c.06-.28.13-.56.19-.83l.09-.4c.06-.27.12-.53.17-.79.13-.58.25-1.14.35-1.65a1.11,1.11,0,0,0-.07.16c-.12-.77-.23-1.55-.34-2.32-.86-1.49-2.14-3.69-3.62-6.22l-.8-1.36-1.06-1.81-.81-1.38c-.28-.46-.55-.93-.83-1.4l-1.32-2.23a56.55,56.55,0,0,1-5.34,3.72,5.84,5.84,0,0,0-.93.08l.44.22a60.52,60.52,0,0,1-29.4,8.47l-1.5,0C540.64,1129.93,539,1131,538.88,1130.88Z"
              ></path>
              <path
                fill="#38385c"
                d="M583.64 1116.52zm.64-31.65l-.31-.18a37.82 37.82 0 016.65 7A17.24 17.24 0 00584.28 1084.87zm-16.42 31.65zm3.44 4.75c-13.67 2-30.36 3.79-44.39 3.26l.13.21h0c1 1.76 2.35 5.35 4 7.26a4.12 4.12 0 001.22 1v0c.31-2.11 1.36-4 3.45-4.42 0 0 2.46 2.78 3.15 2.31h0c.06.07 1.76-1 1.85-1l1.5 0a60.55 60.55 0 0029.41-8.47zm-42.71-4.75h-4.08v0h4.08zm.94-24.72l.21.09a37.67 37.67 0 016.09-6.59A22.42 22.42 0 00529.53 1091.8zM597.92 1116.43c-.41 1.73-.82 3.47-1.28 5.17-.27 1-.56 2-.87 3l-3.06 6.29-.31.3-.11.11 0 0a2.27 2.27 0 01-.75.5c-.94.93-4.61 1.1-5.2 2.14a1.11 1.11 0 00-.07.16c-.11-.77-.23-1.55-.34-2.32 0-.3-.09-.6-.13-.9q-.87-5.87-1.73-11.71l4.76-.87c4-.75 6.85-1.34 8.19-1.63h0l.79-.17v-.07z"
              ></path>
              <path
                fill="#e88a78"
                d="M577.47,1117.42l1.32,2.23.83,1.4.81,1.38,1.06,1.81.8,1.36c1.48,2.53,2.75,4.73,3.62,6.22,0-.3-.09-.6-.13-.89q-1.14-7.7-2.27-15.38h0c-.16-1.06-.31-2.11-.47-3.17l-1,1A57.42,57.42,0,0,1,577.47,1117.42Z"
              ></path>
              <path
                fill="#82635a"
                d="M565.77 1089.22c.37 0 .75.07 1.12.12s.63.08.94.14l.64.11.67.14.66.16q.44.1.87.24c.37.11.75.23 1.11.36l.31.11c.24.09.49.17.73.27l.42.17.3.12a3.49 3.49 0 01.34.15l1 .47c.33.16.66.33 1 .51s.35.19.52.29l.37.22h0l.1.06.21.13.27.17a27.14 27.14 0 013.19 2.33l.16.14a27.53 27.53 0 012.13 2c.1.11.2.21.29.32s.3.33.44.5l.42.5c.17.2.33.41.49.61s.19.24.28.37.31.43.46.65l.35.51c.08.12.16.24.23.36l.12.19c.05.07.08.14.13.2.15.25.3.5.44.76s.28.51.41.78.26.52.38.79.24.53.35.8.11.27.16.4c.05-.12.09-.25.13-.37l.08-.26s0 0 0 0l0-.09h0v0c0-.09.05-.19.08-.28s.11-.43.16-.65 0-.16.05-.24a2 2 0 000-.24l.06-.41a.22.22 0 000-.08.41.41 0 000-.11c0-.12 0-.23 0-.35a.22.22 0 000-.08c0-.18 0-.35 0-.53s0-.3 0-.45 0-.51 0-.76v-.14a2.81 2.81 0 000-.29 2.45 2.45 0 000-.27c0-.19 0-.39 0-.58 0 0 0 0 0-.06 0-.2-.05-.39-.08-.59s0-.29-.08-.44l-.09-.44c0-.06 0-.13 0-.19a2 2 0 00-.07-.26c0-.12-.06-.24-.1-.36a.83.83 0 000-.15c0-.11-.06-.23-.1-.34-.09-.29-.19-.57-.29-.85l-.15-.39c-.12-.29-.25-.57-.38-.85l-.15-.3h0l-.27-.49c0-.1-.11-.19-.17-.29s-.12-.19-.17-.29-.24-.38-.37-.56l-.26-.37c-.14-.18-.27-.36-.41-.53l-.44-.51a3.26 3.26 0 00-.31-.33 6.25 6.25 0 00-.45-.44l-.47-.42-.32-.27-.45-.34-.31-.22-.48-.32-.49-.29a3.65 3.65 0 00-.34-.18l-.34-.17-.32-.15-.44-.19-.33-.14-.15 0-.16-.06-.32-.11-.4-.13c-.32-.1-.65-.19-1-.26l-.47-.1-.44-.08-.15 0h-.07l-.37-.05-.46 0-.21 0-.35 0-.56 0H574c-.32 0-.63 0-1 0l-.31 0-.63.08h0l-.62.11-.62.13-.61.16-.55.17-.12 0-.41.14-.18.07-.51.21-.43.2-.37.18-.1 0-.36.19A10.78 10.78 0 00565.77 1089.22zM597.84 1116.45v.07l-.79.17.57-.17z"
              ></path>
              <path
                fill="#101029"
                d="M581.36 1132.16l-12-4.55A10.48 10.48 0 01581.36 1132.16zM551.9 1129l-10.3 3.2A9.7 9.7 0 01551.9 1129zM548.23 1132.25c1.37-.43 3 .86 3.61 2.87s0 4-1.34 4.4-3-.85-3.61-2.86S546.87 1132.68 548.23 1132.25zM570.88 1132.55c1.42-.13 2.73 1.46 2.92 3.56s-.81 3.9-2.24 4-2.73-1.47-2.92-3.56S569.45 1132.68 570.88 1132.55z"
              ></path>
              <path
                fill="#fb6562"
                d="M556.59,1131c.58,2.84,1.15,5.68,1.73,8.52a2.34,2.34,0,1,0-3.6,2.57l6.27,4.44"
              ></path>
              <path
                fill="#fff"
                d="M554.91,1150.81a18.62,18.62,0,0,0,20.39-7.61,11.75,11.75,0,0,1-7.31,12C563.26,1157,557.43,1155.15,554.91,1150.81Z"
              ></path>
              <path
                fill="#fbb089"
                d="M462.9,1149.23q1.5-10.18,3-20.36c.27-1.85-1.8-3.46-3.65-3.17a5.11,5.11,0,0,0-3.78,3.76,14.94,14.94,0,0,0-.23,5.58,19.34,19.34,0,0,0-6.1-1.69,6.59,6.59,0,0,0-5.66,2.3,10.54,10.54,0,0,0,6.94,16.69c.1.63.19,1.26.29,1.89A25.53,25.53,0,0,1,462.9,1149.23Z"
              ></path>
              <path
                fill="#f75c3d"
                d="M469.66 1156l-17 5.62c-.55-2.46-1.08-5-1.6-7.6l12.8-5.28S466.1 1151.46 469.66 1156zM597.92 1114c0 .84 0 1.68-.08 2.5l-.23.07H522.69c-.06-.85-.09-1.7-.09-2.57s0-2 .12-3a37.35 37.35 0 013.4-12.91 36.76 36.76 0 013.62-6.15A37.69 37.69 0 01596 1102c.28.83.53 1.68.75 2.53A38.58 38.58 0 01597.92 1114z"
              ></path>
              <path
                fill="#e44531"
                d="M597.61,1116.52l-.57.17h0c-1.34.29-4.22.88-8.19,1.63-1.45.27-3,.57-4.76.87-3.74.66-8.06,1.38-12.74,2.07-13.67,2-30.36,3.79-44.39,3.26-7-.26-13.31-1.1-18.28-2.77-.07,0,0,0,0-.12-.33-6,14-5.1,14-5.1h75Z"
              ></path>
              <path
                fill="#38385c"
                d="M736.09,1333.9v182.36a71.6,71.6,0,0,1-.74,10.31H238.81a71.6,71.6,0,0,1-.74-10.31V1333.9a71.82,71.82,0,0,1,71.82-71.82H664.26A71.82,71.82,0,0,1,736.09,1333.9Z"
              ></path>
              <polygon
                fill="#a54545"
                points="427.78 1262.08 427.46 1262.08 427.46 1262.07 427.78 1262.08"
              ></polygon>
              <path
                fill="#38385c"
                d="M381.25,1248.36v.35h32.08Zm0,0v.35h32.08Zm0,0v.35h32.08Zm100.89-211.07a83.91,83.91,0,0,0-80.47,60.13l-48.43,163.91,66.2.74H652.22v-9.51H423.89l-62.93-.68,20.29-66.12L407,1101.84c10-32.64,41.14-55,76.48-55H657.57v3.71h7.71v-13.22ZM381.25,1248.36v.35h32.08Zm0,0v.35h32.08Z"
              ></path>
              <path fill="#e87979" d="M381.25,1248.36v.35h32.08Z"></path>
              <path
                fill="#1a1a35"
                d="M657.58,1050.51v-3.71H483.48c-35.34,0-66.47,22.4-76.48,55l-25.75,83.92L361,1251.88l62.93.68H652.23v239.26h13.06V1050.5h-7.71ZM427.26,1248.86H427l-13.64-.15H381.25v-49.2l4.22-13.74,25.22-82.19c9.65-31.46,39.66-53,73.73-53H652.23v198.34h-225Z"
              ></path>
              <polygon
                fill="#f7bcbc"
                points="413.33 1248.71 381.25 1248.71 381.25 1248.36 413.33 1248.71"
              ></polygon>
              <path
                fill="#fbb750"
                d="M238.2,1413v60.63h-.84a30.34,30.34,0,0,1-26.24-45.55A30.38,30.38,0,0,1,237.36,1413Z"
              ></path>
              <path
                fill="#fdec88"
                d="M238.2,1460v13.67h-.84a30.34,30.34,0,0,1-26.24-45.55,30,30,0,0,1,4.85-6.28A66.31,66.31,0,0,0,238.2,1460Z"
              ></path>
              <path
                fill="#fdfdfc"
                d="M233,1473.33a30.34,30.34,0,0,1-21.85-45.23C211,1445.31,219.5,1462.57,233,1473.33Z"
              ></path>
              <path
                fill="#1a1a35"
                d="M238.88,1411.72c13.08,18.49,12.33,45.58-1.74,63.32C237,1454.45,236.82,1433.86,238.88,1411.72Z"
              ></path>
              <rect
                width="692.1"
                height="521.83"
                x="665.29"
                y="970"
                fill="#38385c"
              ></rect>
              <path
                fill="#1a1a35"
                d="M586.88 1493.81H321.58a136.65 136.65 0 01265.3 0zM1285.61 1493.81H1012.4c0-57.35 61.16-103.84 136.6-103.84S1285.61 1436.46 1285.61 1493.81z"
              ></path>
              <path
                fill="#1a1a35"
                d="M1396.68,1509.2a17.38,17.38,0,0,1-17.37,17.37h-1161a17.37,17.37,0,0,1,0-34.74h1161A17.37,17.37,0,0,1,1396.68,1509.2Z"
              ></path>
              <ellipse
                cx="454.23"
                cy="1526.57"
                fill="#febe3d"
                rx="118.91"
                ry="112.6"
              ></ellipse>
              <ellipse
                cx="454.23"
                cy="1526.57"
                fill="#38385c"
                rx="91.67"
                ry="86.81"
              ></ellipse>
              <ellipse
                cx="454.23"
                cy="1526.57"
                fill="#febe3d"
                rx="76.42"
                ry="72.37"
              ></ellipse>
              <ellipse
                cx="1149.01"
                cy="1526.57"
                fill="#febe3d"
                rx="118.91"
                ry="112.6"
              ></ellipse>
              <ellipse
                cx="1149.01"
                cy="1526.57"
                fill="#38385c"
                rx="91.67"
                ry="86.81"
              ></ellipse>
              <ellipse
                cx="1149.01"
                cy="1526.57"
                fill="#febe3d"
                rx="76.42"
                ry="72.37"
              ></ellipse>
              <rect
                width="10.69"
                height="85.51"
                x="381.25"
                y="1199.51"
                fill="#f8a23a"
              ></rect>
              <rect
                width="28"
                height="12.97"
                x="353.25"
                y="1248.36"
                fill="#f8a23a"
              ></rect>
              <path
                fill="#1a1a35"
                d="M425.44,1252.57v-74.28a12.5,12.5,0,0,1,12.5-12.5h0v86.78Z"
              ></path>
              <rect
                width="412"
                height="213.57"
                x="1438.05"
                y="1421.43"
                fill="#febe3d"
              ></rect>
              <rect
                width="100.5"
                height="213.57"
                x="1438.05"
                y="1421.43"
                fill="#f8a23a"
              ></rect>
              <rect
                width="242.49"
                height="201.18"
                x="1488.31"
                y="1220.25"
                fill="#febe3d"
              ></rect>
              <rect
                width="61.21"
                height="201.18"
                x="1488.31"
                y="1220.25"
                fill="#f8a23a"
              ></rect>
              <polygon
                fill="#ec7f37"
                points="1487.68 1421.92 1549.51 1415.92 1674.91 1422.85 1487.68 1421.92"
              ></polygon>
              <rect
                width="84.17"
                height="19.76"
                x="1753.61"
                y="1439.75"
                fill="#fff"
              ></rect>
              <rect
                width="84.17"
                height="19.76"
                x="1754.96"
                y="1469.9"
                fill="#fff"
              ></rect>
              <rect
                width="58.86"
                height="19.76"
                x="1663.15"
                y="1231.83"
                fill="#fff"
              ></rect>
              <rect
                width="58.86"
                height="19.76"
                x="1663.15"
                y="1258.84"
                fill="#fff"
              ></rect>
              <path
                fill="#f75c3d"
                d="M1511.56,1003.58c0-56,44.19-101.34,98.71-101.34S1709,947.61,1709,1003.58,1614.3,1162,1614.3,1162,1511.56,1059.55,1511.56,1003.58Z"
              ></path>
              <circle
                cx="1610.27"
                cy="1003.69"
                r="53.49"
                fill="#1a1a35"
              ></circle>
            </g>
            <g>
              <path
                fill="#febe3d"
                d="M953.18 1295.53h0a9.06 9.06 0 019.06-9.06h346a9.06 9.06 0 019.06 9.06h0a9.06 9.06 0 01-9.06 9.06h-346A9.06 9.06 0 01953.18 1295.53zM687.18 1000h0a9.06 9.06 0 019.06-9.06h633.89a9.06 9.06 0 019.06 9.06h0a9.06 9.06 0 01-9.06 9.06H696.24A9.06 9.06 0 01687.18 1000zM1086.24 1328.53h0a9.06 9.06 0 019.06-9.06h210.89a9.06 9.06 0 019.06 9.06h0a9.06 9.06 0 01-9.06 9.06H1095.3A9.06 9.06 0 011086.24 1328.53zM1149.24 1361.53h0a9.06 9.06 0 019.06-9.06h149.89a9.06 9.06 0 019.06 9.06h0a9.06 9.06 0 01-9.06 9.06H1158.3A9.06 9.06 0 011149.24 1361.53zM1131.41 843.52H1025.15v.8q-22.37-.26-44.92.63v-1.43H863.89a34.5 34.5 0 011.86-31.31c8.41-13.81 25-21 41.08-22.18s32.15 2.74 47.83 6.63c-12.25-19.39-9.56-47 6.23-63.6s43.17-20.81 63.18-9.6 30.78 36.74 24.83 58.9c19.7-13.65 48.61-12 66.66 3.77C1131.38 800 1137.52 823.54 1131.41 843.52z"
              ></path>
              <rect
                width="44.92"
                height="70.52"
                x="980.86"
                y="822.8"
                fill="#38385c"
              ></rect>
              <polygon
                fill="#38385c"
                points="1003.69 771.98 964.93 826.54 1042.45 826.54 1003.69 771.98"
              ></polygon>
              <path
                fill="#febe3d"
                d="M238.94,924V856.49a51.24,51.24,0,0,1,51.24-51.24H585.7a51.24,51.24,0,0,1,51.24,51.24V924a51.24,51.24,0,0,1-51.24,51.24H290.19A51.23,51.23,0,0,1,238.94,924Z"
              ></path>
              <polygon
                fill="#38385c"
                points="301.96 860.4 309.09 883.46 332.15 883.46 313.49 897.72 320.62 920.78 301.96 906.53 283.3 920.78 290.43 897.72 271.77 883.46 294.83 883.46 301.96 860.4"
              ></polygon>
              <polygon
                fill="#38385c"
                points="369.87 860.4 376.99 883.46 400.06 883.46 381.4 897.72 388.52 920.78 369.87 906.53 351.21 920.78 358.34 897.72 339.68 883.46 362.74 883.46 369.87 860.4"
              ></polygon>
              <polygon
                fill="#38385c"
                points="437.79 859.71 444.92 882.77 467.98 882.77 449.32 897.03 456.45 920.09 437.79 905.84 419.13 920.09 426.26 897.03 407.6 882.77 430.66 882.77 437.79 859.71"
              ></polygon>
              <polygon
                fill="#38385c"
                points="506.19 859.71 513.32 882.77 536.38 882.77 517.72 897.03 524.85 920.09 506.19 905.84 487.54 920.09 494.66 897.03 476 882.77 499.07 882.77 506.19 859.71"
              ></polygon>
              <polygon
                fill="#38385c"
                points="573.92 859.71 581.05 882.77 604.11 882.77 585.45 897.03 592.58 920.09 573.92 905.84 555.26 920.09 562.39 897.03 543.73 882.77 566.79 882.77 573.92 859.71"
              ></polygon>
              <polygon
                fill="#febe3d"
                points="635.72 926.04 631.06 1083.6 574.71 975.57 635.72 926.04"
              ></polygon>
            </g>
          </svg>
        </div>

        <div class="row">
          <div class="col-md-4 order-md-2 mb-4">
            <nav class="bg-navbar rounded-2  p-2  d-flex justify-content-between align-items-center mb-3">
              <h4 class="mt-2 text-white">Your cart</h4>
              <h4 class="mt-2  text-white">{totalNoOfProduct}</h4>
            </nav>
            <ul class="list-group mb-3">
              {cartDetails.map((item) => (
                <li class="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 class="my-0">{item.product_name}</h6>
                  <small class="text-muted">Brief description</small>
                </div>
                <span class="text-muted">₹{item.cart_amount}</span>
              </li>
              ))}
              
              {/* <li class="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 class="my-0">Second product</h6>
                  <small class="text-muted">Brief description</small>
                </div>
                <span class="text-muted">$8</span>
              </li>
              <li class="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 class="my-0">Third item</h6>
                  <small class="text-muted">Brief description</small>
                </div>
                <span class="text-muted">$5</span>
              </li> */}
              <li class="list-group-item d-flex justify-content-between">
                <span>Total (INR)</span>
                <strong>₹{totalAmount}</strong>
              </li>
            </ul>
            <div class="border p-3 rounded-3">
              <h5 class="">Payment Method</h5>
              <form class=" p-3 bg-payment rounded-2">
                <div class="d-flex justify-content-between  ">
                  <div class="d-flex flex-column">
                    <h5 class="mb-1 fw-3 text-white">Pay Now</h5>
                    <p class="mb-0 text-white">
                      Payments are secured by RazorPay
                    </p>
                  </div>
                  <div class="d-flex align-items-center pe-3">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="radioNoLabelX"
                      id="radioNoLabel11"
                      value=""
                      aria-label="..."
                    />
                  </div>
                </div>
              </form>
              <div class="mt-2 d-flex">
                <div>
                  <img
                    src="https://badges.razorpay.com/badge-dark.png"
                    alt="razorpay"
                    class="razorpay-icon"
                  />
                </div>

                <p style={{ fontSize: "13px" }} class="ps-2 mt-1">
                  UPI, Net Banking, Debit, and Credit Cards Accepted
                </p>
              </div>
              <div class="mt-4">
                <button
                  class="border-0 text-white product-btn w-100 p-3 px-3 rounded-2 d-flex justify-content-between"
                  data-toggle="modal"
                  data-target="#myModal"
                  
                >
                  <span class="fw-semibold">₹{totalAmount}</span>
                  <span>Place Order</span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <div class="text-center mt-3">
                <p>
                  by continuing, you agree to our{" "}
                  <a href="#" class="product-prp">
                    privacy policy
                  </a>{" "}
                  and{" "}
                  <a href="#" class="product-prp">
                    terms & conditions{" "}
                  </a>{" "}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-8 order-md-1">
            <nav class="bg-navbar rounded-2  p-2 ">
              <h4 class="mt-2 text-white">Billing address</h4>
            </nav>

            <form class="needs-validation mt-3" novalidate onSubmit={handleSubmit} ref={formRef}>
              <div class="row">
                <div class="mb-3 ">
                  <label for="PersonName">
                    Contact Person Name<sup class="text-danger">*</sup>
                  </label>
                  <input
                    type="text"
                    class="form-control mt-2"
                    id="PersonName"
                    placeholder="Enter Your PersonName"
                    name="contact_person_name"
                    onChange={handleChange}
                    required
                  />
                  <div class="invalid-feedback">
                    Please enter your shipping address.
                  </div>
                </div>
                <div class="mb-3">
                  <label for="username">
                    Delivery Contact (Whatsapp) <sup class="text-danger">*</sup>{" "}
                  </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text mt-2">+91</span>
                    </div>
                    <input
                      type="text"
                      class="form-control mt-2"
                      id="username"
                      placeholder="Enter Your Number"
                      required
                      name="contact_whatsapp_no"
                      onChange={handleChange}
                    />
                    <div class="invalid-feedback" style={{ width: "100%" }}>
                      Your username is required.
                    </div>
                  </div>
                </div>
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="same-address"
                  />
                  <span class="custom-control-label ps-2 " for="same-address">
                    Click here if your delivery contact number is the same as
                    your WhatsApp number
                  </span>
                </div>
                <div class="my-3 ">
                  <label for="email">
                    House No,Building Name <sup class="text-danger">*</sup>
                  </label>
                  <input
                    type="email"
                    class="form-control mt-2"
                    id="email"
                    placeholder="Enter the House No,Building Name"
                    name="house_no"
                    onChange={handleChange}
                  />
                  <div class="invalid-feedback">
                    Please enter a valid email address for shipping updates.
                  </div>
                </div>
                <div class="my-2 ">
                  <label for="street">
                    Street Name,Area <sup class="text-danger">*</sup>
                  </label>
                  <input
                    type="street"
                    class="form-control mt-2"
                    id="street"
                    name="street"
                    onChange={handleChange}
                    placeholder="Enter the Street Name,Area"
                  />
                  <div class="invalid-feedback">
                    Please enter a Street Name,Area for shipping updates.
                  </div>
                </div>
                <div class="col-md-6 my-3">
                  <label for="LandMark  ">Land Mark</label>
                  <input
                    type="text"
                    class="form-control mt-2"
                    id="LandMark"
                    placeholder="Enter Your LandMark"
                    name="landmark"
                    onChange={handleChange}
                    required
                  />
                  <div class="invalid-feedback">LandMark is required.</div>
                </div>
                <div class="col-md-6 my-3">
                  <label for="C/D">
                    City/District <sup class="text-danger">*</sup>
                  </label>
                  <input
                    type="text"
                    class="form-control mt-2"
                    id="C/D"
                    placeholder="Enter Your City/District"
                    name="city"
                    onChange={handleChange}
                    required
                  />
                  <div class="invalid-feedback">City/District is required.</div>
                </div>
                <div class="col-md-6 my-2">
                  <label for="State">Select State</label>
                  <input
                    type="text"
                    class="form-control mt-2"
                    id="State"
                    placeholder="Enter Your State"
                    
                    name="state"
                    onChange={handleChange}
                    required
                  />
                  <div class="invalid-feedback">State is required.</div>
                </div>
                <div class="col-md-6 my-2">
                  <label for="zip">
                    {" "}
                    PostalCode/Zip<sup class="text-danger">*</sup>
                  </label>
                  <input
                    type="text"
                    class="form-control mt-2"
                    id="zip"
                    placeholder="Enter Your Zip"
                   
                    name="pincode"
                    onChange={handleChange}
                    required
                  />
                  <div class="invalid-feedback">City/District is required.</div>
                </div>
              </div>
              <div class="my-3">
                <label for="email">
                  Email <span class="text-muted">(Optional)</span>
                </label>
                <input
                  type="email"
                  class="form-control mt-2"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  placeholder="you@example.com"
                />
                <div class="invalid-feedback">
                  Please enter a valid email address for shipping updates.
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <!-- The Modal --> */}
      <div class="modal fade" id="myModal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            {/* <!-- Modal Header --> */}
            <div class="modal-header justify-content-between">
              <span class="align-items-center fw-semibold">
                Verify Your Contact
              </span>
              <button
                type="button"
                class="close border-0 rounded-circle "
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>

            {/* <!-- Modal body --> */}
            <div class="modal-body">
              Please confirm your WhatsApp number as{" "}
              <span class="fw-semibold">+918807667074.</span> We will send the
              final design and order tracking status to this WhatsApp number and
              to <span class="fw-semibold">ragul@gmail.com.</span>
            </div>

            {/* <!-- Modal footer --> */}
            <div class="modal-footer">
              <a href="#">
                <button
                  type="button"
                  class="border-0 rounded-2 p-2 px-3 bg-muted"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </a>
              <a href="#">
                <button
                  type="button"
                  class="border-0 product-btn text-white rounded-2 p-2 px-3"
                  data-dismiss="modal"
                  onClick={triggerSubmit}
                >
                  Submit
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Checkout;
